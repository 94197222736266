import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ArtistPageTemplate = ({ data: { post } }) => {
  var opiniontxt=parse(post.opinion);
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "breadcrumb": 'Libros > '+post.tag,
    mainEntity: [
      {
        "@type": "Book",
        bookFormat: "https://schema.org/Paperback",
        image: post.image,
        inLanguage: "Catalan",
        isbn: post.ISBN,
        name: parse(post.nombre),
        review: [{
          "@type": "Review",
          author: "gremillibrevell.cat",
          name: "Opinió de "+parse(post.nombre),
          reviewBody: parse(post.plainopinion),
          reviewRating: {
            "@type": "Rating",
            "ratingValue": "5"
           }
        }]
      },
    ],
  };
  const schemaAsString = JSON.stringify(schema, null, 2);
  var autores=JSON.parse(post.Autores).join(', ');
  const featuredImage = {
    //data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    //alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <Layout>
    <Helmet>
        <script src={'/menu.js'} />
        <link rel="icon" href={'/ico32.jpg'} sizes="32x32" />
        <link rel="icon" href={'/ico192.jpg'} sizes="192x192" />
        <link rel="apple-touch-icon" href={'/ico180.jpg'} />
        <meta name="msapplication-TileImage" content={'/ico270.jpg'} />
        <script type="application/ld+json">{schemaAsString}</script>
    </Helmet>
    <Seo title={`Sinopsi i opinió de ${post.nombre}`} />
      <article
        className="global-wrapper blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >

      <div class="jumbotron p-4 p-md-5 text-black rounded bg-light">
        <div class="col-md-10 px-0">
          <h1 class="display-4 font-italic">{parse(post.nombre)}</h1>
          <p class="lead my-4">Sinopsi i opinió del llibre</p>
        </div>
      </div>
          <h2 className="mt-4">Tot sobre el llibre {post.nombre}</h2>
          <div class="row">
            <div className="col-12 col-md-6">
              <a href={`${post.url}`} rel="nofollow noopener noreferer" target="_blank">
              <img className="hamabeads" title={`${post.nombre}`} src={`${post.image}`} alt={`${post.nombre}`} />
              </a>
            </div>
            <div className="col-12 col-md-6">
              <table className="table table-striped">
                <tbody>
                <tr>
                  <td>EAN</td>
                  <td>{post.EAN}</td>
                </tr>
                <tr>
                  <td>ISBN</td>
                  <td>{post.ISBN}</td>
                </tr>
                <tr>
                  <td>Autors</td>
                  <td>{autores}</td>
                </tr>
                <tr>
                  <td>Comprar</td>
                  <td><a className="amazon basket" href={`${post.url}`} rel="nofollow noopener noreferer" target="_blank">Compra a Amazon</a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        {!!post.sinopsis && (
          <section itemProp="articleBody">
          <h2 className="mt-4">Sinopsi de {post.nombre}</h2>
            <div>{parse(post.sinopsis)}</div>
          </section>
        )}
        {!!post.opinion && (
          <section itemProp="articleBody">
          <h2 className="mt-4">Opinió de {post.nombre}</h2>
            <div>{parse(post.opinion)}</div>
          </section>
        )}
        <section>
          
        </section>
      </article>
    </Layout>
  )
}

export default ArtistPageTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
  ) {
    post: mysqlHamabeadsEn(id: { eq: $id }) {
      id
      slug
      image
      nombre
      sinopsis
      url,
      tag
      opinion
      EAN
      ISBN
      Autores
      plainopinion
    }
  }
`
